<template>
	<div class="login">
		<div class="notLog" v-if="!isLogin">
			<div v-if="!showEmail">
				<h1>Login ! ^_^</h1>
				<p> Connect to access to all Kimbapp features, save your progress and book teacher </p>
				<div class="btn" @click="signWithGoogle">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" /><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" /><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" /><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" /><path fill="none" d="M0 0h48v48H0z" /></g></svg>
					Sign-in with Google
				</div><br>
				<div class="btn" @click="signWithGoogle">
					<svg viewBox="0 0 842 1e3" xmlns="http://www.w3.org/2000/svg"><path d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z" /></svg>
					Sign-in with Apple
				</div><br>
				<div class="btn" @click="signWithEmail">
					<svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
						<path d="m454.4 90.667h-399.79c-33.706 0-54.613 19.626-54.613 51.093v228.37c0 30.613 21.333 51.2 53.12 51.2h401.28c32.853 0 57.6-21.973 57.6-51.2v-228.37c0-31.04-22.613-51.093-57.6-51.093zm-399.68 21.333h399.68c5.973 0 16.427 1.067 24.533 6.4l-214.08 172.16-232.43-172.91c5.44-3.626 12.8-5.653 22.293-5.653zm435.95 258.13c0 19.627-18.24 29.867-36.267 29.867h-401.28c-19.947 0-31.787-11.2-31.787-29.867v-228.37c0-1.813 0.213-3.733 0.427-5.547l237.01 176.32c3.947 2.88 9.28 2.773 13.013-0.213l218.24-175.47c0.32 1.6 0.533 3.307 0.64 5.013l1e-3 228.27z" />
					</svg>
					Sign-in with Email
				</div><br>
				<div class="register">
					<p>Don't have an account? Create one for free</p>
					<div class="btn registerbtn" @click="toRegister">
						Create a new account
					</div>
				</div>
			</div>
			<form action="#" @submit.prevent="submit" v-if="showEmail">
				<p>Login with Email</p>
				<div>
					<input
						id="email"
						type="email"
						name="email"
						placeholder="Email login"
						value
						required
						autofocus
						v-model="form.email"
					>
				</div>
				<div>
					<input
						id="password"
						type="password"
						name="password"
						placeholder="Password login"
						required
						v-model="form.password"
					>
				</div>
				<button type="submit" class="btn2">
					Connect
				</button>
			</form>
			<div class="back" @click="signWithEmail" v-if="showEmail">
				back to login option
			</div>
		</div>
		<div class="notLog" v-else @click="signout">
			<span>
				Logout
			</span>
		</div>
	</div>
</template>

<script>
import UserData from '@/data/UserData'
import Firebase from '@/data/Firebase'
import router from '@/router'
export default {
	data () {
		return {
			form: {
				email: '',
				password: ''
			},
			showEmail: false,
			error: null
		}
	},
	computed: {
		isLogin () {
			return UserData.isLogin.value
		}
	},
	methods: {
		toRegister () {
			router.push('register')
		},
		submit () {
			Firebase.signInWithEmailAndPassword(this.form.email, this.form.password)
		},
		signWithEmail () {
			this.showEmail = !this.showEmail
		},
		signWithGoogle () {
			Firebase.signInWithGoogle()
		},
		signWithApple () {
			Firebase.signInWithApple()
		},
		signout () {
			Firebase.signOut()
		}
	}
}
</script>

<style lang="stylus" scoped>
.login
	background rgba(0,0,0,0.4)
	min-height 100%
	height 100%
	width 100%
	position absolute
	top 0
	left 0
	h1
		color #fff
		font-size 24px
	p
		color #fff
		font-size 13px
	form
		margin-top 30px
		text-align center
		font-size 13px
		label
			width 100px
			text-align right
			padding-right 20px
			display inline-block
		input
			user-select none
			width 150px
			border-radius 15px
			border none
			box-shadow none
			background #fff
			padding 10px 15px
		div
			margin-bottom 20px
		.btn2
			padding 10px 15px
			background #fff
			border none
			box-shadow none
			border-radius 15px
	.btn
		font-size 13px
		line-height 18px
		padding 10px 20px
		text-align left
		background #fff
		display inline-block
		border-radius 25px
		margin-top 20px
		min-width 200px
		user-select none
		cursor pointer
		svg, img
			width 18px
			vertical-align middle
			margin-right 10px
			margin-left 30px
		&.registerbtn
			text-align center
.back
	color #fff
	font-size 11px
	padding 20px
	cursor pointer
.notLog
	max-width 300px
	width 300px
	position absolute
	top 50%
	left calc(50% - 150px)
	transform translateY(-60%)
	span
		border-radius 15px
		background #fff
		padding 10px 15px
		user-select none
		cursor pointer
.register
	margin-top 40px
	.btn
		margin-top 0

</style>
